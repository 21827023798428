.react-calendar {
  margin: 0 auto;
  width: 720px;
  max-width: 720px;
  background: transparent;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 250px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  gap: 36px;
  justify-content: center;
  height: 44px;
  margin-bottom: 40px;
}
.react-calendar__navigation button {
  background: none;
}
.react-calendar__navigation .react-calendar__navigation__label {
  flex-grow: 0 !important;
  font-size: 24px;
  font-weight: bold;
}

abbr {
  text-decoration: none !important;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}
.react-calendar__month-view__weekdays__weekday.react-calendar__month-view__weekdays__weekday--weekend {
  color: #949494;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  padding-bottom: 18px;
  border-bottom: 2px solid #d8d8d8;
}
.react-calendar__month-view__weekNumbers {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day {
  font-size: 20px;
  font-weight: 'bold';
}
.react-calendar__month-view__days__day--weekend {
  color: #949494;
}

.react-calendar__tile {
  height: 44px;
  max-width: 100%;
  background: none;
  text-align: center;
  position: relative;
  padding: 10px;
}
.react-calendar__tile.prev,
.react-calendar__tile.next,
.react-calendar__tile.dot,
.react-calendar__tile.mid,
.react-calendar__tile.none {
  margin: 10px 0;
  text-align: center;
}
.react-calendar__tile.prev::before {
  content: '';
  width: 80%;
  height: 30px;
  border-radius: 999px 0 0 999px;
  background: #fbb500;
  position: absolute;
  padding-left: 10px;
  margin-top: 5px;
  top: 0;
  bottom: 0;
  right: 0;
}
.react-calendar__tile.mid::before {
  content: '';
  width: 100%;
  height: 30px;
  background: #fbb500;
  position: absolute;
  padding-left: 10px;
  margin-top: 5px;
  top: 0;
  bottom: 0;
  right: 0;
}
.react-calendar__tile.next::before {
  content: '';
  width: 80%;
  height: 30px;
  border-radius: 0 999px 999px 0;
  background: #fbb500;
  position: absolute;
  padding-right: 10px;
  margin-top: 5px;
  top: 0;
  bottom: 0;
  left: 0;
}
.react-calendar__tile.dot::before {
  content: '●';
  font-size: 34px;
  color: #fbb500;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
}

.react-calendar__tile.new::after {
  content: '•';
  font-size: 20px;
  color: #c21a1a;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.react-calendar__tile abbr {
  display: inline-block;
  min-width: 40px;
  border-radius: 999px;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 0;
  left: 0;
}

.react-calendar__tile--now {
  position: relative;
  color: white;
}
.react-calendar__tile--now::before {
  content: '●';
  font-size: 34px;
  color: #5078df;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
}
.react-calendar__tile--now.dot::before {
  content: '●';
  font-size: 34px;
  color: #434343;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
}

@media (max-width: 480px) {
  .react-calendar {
    max-width: 350px;
  }
  .react-calendar__navigation .react-calendar__navigation__label {
    flex-grow: 0 !important;
    font-size: 18px;
    font-weight: bold;
  }
  .react-calendar__navigation {
    display: flex;
    font-size: 24px;
    gap: 20px;
    justify-content: center;
    height: 30px;
    margin: 18px;
  }
}
